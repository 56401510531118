body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


.btn .btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }
